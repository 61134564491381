<template>
  <div class="list-page">
    <div class="list-container">
      <v-row v-if="isLoading" class="justify-center mt-2">
        <v-progress-circular color="primary" indeterminate />
      </v-row>
      <v-list v-else class="pa-0 ma-0">
        <div v-for="(item, index) in items" :key="item">
          <v-list-group :value="false" @click="openItem(index)">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="ml-2 title-item">
                  <div class="d-flex">
                    <div v-if="item.unread" class="picker mt-1 mr-1"></div>
                    {{ item.title }}
                  </div>
                </v-list-item-title>
                <v-list-item-subtitle class="ml-2 subtitle-item">
                  {{ formatDate(item.subtitle) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
            <v-list-item style="background: #e7f4f5">
              <v-list-item-content>
                <div style="display: flex; flex-wrap: wrap" class="pl-2">
                  <div class="title-item col-sm-4 col-md-2 col pa-0">
                    Facility:
                  </div>
                  <div class="label-info col-5 col pa-0">
                    {{ item.facilityName }}
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap" class="pl-2">
                  <div class="title-item col-sm-4 col-md-2 col pa-0 pt-2">
                    Physician:
                  </div>
                  <div class="label-info col-5 col pa-0 pt-2">
                    {{ item.physician }}
                  </div>
                </div>
                <div style="display: flex; flex-wrap: wrap" class="pl-2">
                  <div class="title-item col-sm-4 col-md-2 col pa-0 pt-2">
                    Result:
                  </div>
                  <div class="label-info col-5 col pa-0 pt-2">
                    <div
                      v-for="(result, key) in item.result"
                      :key="result.name + key"
                      style="display: flex; flex-wrap: wrap"
                      :class="key > 0 ? 'pt-2' : ''"
                    >
                      <div class="label-info lab-name">{{ result.name }}</div>
                      <v-spacer></v-spacer>
                      <div class="label-info lab-name">{{ result.value }}</div>
                    </div>
                  </div>
                </div>
              </v-list-item-content>
            </v-list-item>
            <div
              style="
                height: 6px;
                background: #a0d5d7;
                border-radius: 0px 0px 16px 16px;
              "
            ></div>
          </v-list-group>
          <v-divider v-if="index < items.length - 1" :key="element"></v-divider>
        </div>
      </v-list>
      <Notify
        v-if="notification.show"
        width="100%"
        :type="notification.type"
        :title="notification.title"
        @consent="goBack"
        :message="notification.message"
      />
    </div>
  </div>
</template>

<script>
import { api } from '../../sharedPlugin';
import moment from 'moment';
require('moment-timezone');

export default {
  data: () => ({
    items: [],
    notification: {
      show: false,
      title: null,
      message: null,
      type: null
    },
    isLoading: false
  }),
  computed: {
    iconDefault() {
      if (this.title === 'Blood test results') {
        return '~/assets/images/Chevron_Down.png';
      } else {
        return '~/assets/images/right.png';
      }
    },
    title() {
      return this.$route?.name;
    }
  },
  methods: {
    onLoad() {
      if (this.title === 'Blood test results') {
        this.getBloodTest();
      }
      if (this.title === 'Embryology results') {
        this.getResults({ type: 'EMBRYOLOGY' });
      }
      if (this.title === 'Semen analysis') {
        this.getResults({ type: 'SEMEN_ANALYSIS' });
      }
    },
    getBloodTest() {
      this.isLoading = true;
      api.Labs.getBloodTest()
        .then(({ data }) => {
          this.items = data.map((item) => {
            item.title = item.labName;
            item.subtitle = item.testDate;
            item.icon = this.iconDefault;
            return item;
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.throwError(error);
        });
    },
    getResults(params) {
      this.isLoading = true;
      api.Labs.getResults(params)
        .then(({ data }) => {
          this.items = data.map((item) => {
            item.title = item.documentName;
            item.subtitle = item.createdAt;
            item.icon = this.iconDefault;
            item.tap = false;
            return item;
          });
          this.isLoading = false;
        })
        .catch((error) => {
          this.throwError(error);
        });
    },
    formatDate(subtitle) {
      var timeZoneAbbr = moment.tz(moment(), moment.tz.guess()).format('z');
      var abbr = '(' + timeZoneAbbr + ')';
      return moment(subtitle).format('MMM DD, YYYY h:ma ') + abbr;
    },
    phoneFormatted(phone) {
      if (phone) {
        phone = phone.replace(/[^0-9]/g, '') || '';
        phone = `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(
          6,
          10
        )}`;
      }
      return phone;
    },
    throwError(error) {
      this.notification.show = true;
      if (error?.code === 404) {
        this.notification.title = 'No Results Found';
        this.notification.type = 'success';
      } else {
        this.notification.title = 'ERROR';
        this.notification.type = 'error';
      }
      this.notification.message = error?.message ?? error;
      this.isLoading = false;
    },
    openItem(index) {
      if (this.title === 'Blood test results') {
        const paramsRead = {
          id: this.items[index].id,
          type: 'BLOOD_TEST'
        };

        api.Labs.readResult(paramsRead);
      }

      // if (this.title === 'Embryology results') {
      //   console.log('openItem Embryology results');
      // }

      // if (this.title === 'Semen analysis') {
      //   console.log('openItem Semen analysis');
      // }
    },
    goBack() {
      this.$router.go(-1);
    }
  },
  created() {
    this.onLoad();
  }
};
</script>

<style lang="scss">
.list-page {
  .list-container {
    height: 92vh;
    overflow-y: scroll;
  }
  .item-action {
    font-size: 1rem;
    height: 36px;
    align-items: center;
  }
  .item-title {
    height: 41px;
    background: #e7f4f5;
  }
  .title-item {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    color: #000000;
  }
  .subtitle-item {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.0024em;
    color: #000000;
  }
  .label-info {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.0041em;
    color: #000000;
  }
  .v-list-group {
    .v-icon {
      color: #d6366e;
    }
  }
  .v-list-group.v-list-group--active {
    .v-icon {
      color: #1f6070;
    }
  }
  .picker {
    background-color: rgb(214, 54, 110);
    border-color: rgb(214, 54, 110);
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
</style>
